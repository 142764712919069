<template>
  <Layout class="list-of-cost">
    <page-layout :page-titles="pageTitle" :bread-crumb-items="breadCrumbItems">
      <template #table-content>
        <div class="Table LOCTable">
          <!--  table toolbar  -->
          <div class="Table__toolbar">
            <div class="Table__toolbar-search">
              <search-input v-if="hasSearch" :value.sync="searchInput" />
              <slot name="left" />
            </div>
            <div class="Table__toolbar-buttons">
              <slot name="right" />
              <div class="d-flex">
                <b-form-select
                  v-model="selectedViewCost"
                  class="form-select m-1"
                  :options="viewCostOptions"
                  @change="dataChange()"
                />
                <b-form-select
                  v-model="selectedYear"
                  class="form-select m-1"
                  :options="yearOptions"
                  @change="dataChange()"
                />
              </div>
            </div>
          </div>
          <!-- start layout table Service -->
          <div class="table-responsive">
            <b-table
              v-if="selectedViewCost === VIEW_COST.APP"
              ref="service-cost-table"
              :items="appItems"
              :fields="appFields"
              thead-class="Table__header"
              tbody-tr-class="Table__row"
              hover
            >
              <template #head()="data">
                <div v-if="!!data.label" class="header">
                  <div class="header--value">
                    <span>{{ data.label }}</span>
                  </div>
                  <div v-if="data.field.sortable" class="header--sort">
                    <SortIcon />
                  </div>
                </div>
                <div v-else class="action">
                  {{ '' }}
                </div>
              </template>

              <template #cell(logo)="data">
                <img class="image" :src="data.value" alt="image" />
              </template>

              <template #cell(category_id)="data">
                <div>
                  {{ getCategoriesLabel(data.item.category_id) }}
                </div>
              </template>

              <template #cell(staff_used)="data">
                <div>{{ data.item.staff_available }}/{{ data.value }}</div>
              </template>

              <template #cell(company_members)="data">
                <div>{{ data.item.staff_available }}/{{ data.value }}</div>
              </template>

              <template #cell(app_cost)="data">
                <div class="text-primary detail-text" @click="showModal({ data })">
                  {{ data.value }}円
                </div>
              </template>

              <template #cell(app_percent)="data">
                <div>{{ data.value }}%</div>
              </template>
            </b-table>
            <!-- end layout table Saas -->
          </div>
          <!-- start layout table 部署 -->
          <div class="table-responsive">
            <b-table
              v-if="selectedViewCost === VIEW_COST.DEPARTMENT"
              ref="department-cost-table"
              :fields="departmentFields"
              :items="departmentItems"
              thead-class="Table__header"
              tbody-tr-class="Table__row"
              hover
            >
              <template #head()="data">
                <div v-if="!!data.label" class="header">
                  <div class="header--value">
                    <span>{{ data.label }}</span>
                  </div>
                  <div v-if="data.field.sortable" class="header--sort">
                    <SortIcon />
                  </div>
                </div>
                <div v-else class="action">
                  {{ '' }}
                </div>
              </template>

              <template #cell(name)="data">
                <router-link
                  :to="{
                    name: 'department-detail',
                    query: {
                      id: data.item.id,
                    },
                  }"
                >
                  {{ data.value }}
                </router-link>
              </template>

              <template #cell(month_average_price)="data">
                <div class="text-primary detail-text" @click="showModal({ data })">
                  {{ data.value }}円
                </div>
              </template>

              <template #cell(month_ago_price)="data">
                <div class="text-primary detail-text" @click="showModal({ data })">
                  {{ data.value }}円
                </div>
              </template>
            </b-table>
            <!-- end layout table Saas -->
          </div>
          <!-- start layout table 従業員 -->
          <div class="table-responsive">
            <b-table
              v-if="selectedViewCost === VIEW_COST.STAFF"
              ref="employee-cost-table"
              hover
              thead-class="Table__header"
              tbody-tr-class="Table__row"
              :fields="staffFields"
              :items="staffItems"
            >
              <template #head()="data">
                <div v-if="!!data.label" class="header">
                  <div class="header--value">
                    <span>{{ data.label }}</span>
                  </div>
                  <div v-if="data.field.sortable" class="header--sort">
                    <SortIcon />
                  </div>
                </div>
                <div v-else class="action">
                  {{ '' }}
                </div>
              </template>

              <template #cell(name)="data">
                <router-link :to="`/customer/employee-detail/${data.item.id}`">
                  {{ data.value }}
                </router-link>
              </template>

              <template #cell(department_id)="data">
                <div>
                  {{ getDepartmentLable(data.item.department_id) }}
                </div>
              </template>

              <template #cell(use_app)="data">
                <div class="text-start">
                  {{ data.value }}
                </div>
              </template>

              <template #cell(cost_average_year)="data">
                <div class="text-primary detail-text" @click="showModal({ data })">
                  {{ data.value }}円
                </div>
              </template>

              <template #cell(cost_month_ago)="data">
                <div class="text-primary detail-text" @click="showModal({ data })">
                  {{ data.value }}円
                </div>
              </template>

              <template #cell(status)="data">
                <div :class="statusClass(data.value)">{{ getStatusLabel(data.item.status) }}</div>
              </template>
            </b-table>
            <!-- end layout table Saas -->
          </div>
          <!--   table paging   -->
          <div class="Table__paging">
            <table-paging
              :current-page.sync="currentPage"
              :per-page.sync="perPage"
              :total="total"
              :options-paging="optionsPaging"
            />
          </div>

          <!-- start dialog add new and edit -->
          <c-modal :id="id" :title="title" :buttons="dialogButtons" :custom-title="customTitle">
            <template #title>
              <div class="ListOfCostModalTitle">
                <h5 class="modal-title">{{ customTitleDetail.title }}</h5>
                <div class="ListOfCostModalTitle__detail">
                  <span>年度： {{ customTitleDetail.year }}</span>
                  <span>対象： {{ customTitleDetail.serviceName }}</span>
                </div>
              </div>
            </template>
            <template #default>
              <list-of-cost-modal-form :form-data.sync="formData" :dialog-fields="dialogFields" />
            </template>
          </c-modal>
          <!-- end dialog add new and edit -->
        </div>
      </template>
    </page-layout>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main/app'
import PageLayout from '@/components/commons/common-page-layout'
import SearchInput from '@/components/commons/common-search-input'
import SortIcon from '@/components/sort-icon'
import { listOfCostData } from '@/services/list-of-cost-service'
import { USER_STATUS, VIEW_COST } from '@/utils/constants'
import TablePaging from '@/components/commons/common-table-paging'
import ListOfCostModalForm from '@/components/forms/list-of-cost-modal-form'
import CModal from '@/components/commons/common-modal'
import { mapGetters } from 'vuex'
import get from 'lodash-es/get'
import { getYearsOptions } from '@/utils/date-utils'
import { departmentManagementService } from '@/services/department-management-service'
export default {
  name: 'ListOfCost',
  components: {
    ListOfCostModalForm,
    PageLayout,
    Layout,
    SearchInput,
    TablePaging,
    SortIcon,
    CModal,
  },
  props: {
    hasSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pageTitle: '費用情報管理',
      title: '',
      searchInput: '',
      id: 'show',
      show: false,
      breadCrumbItems: [
        {
          text: 'SaaS管理',
          href: '/management-service',
        },
        {
          text: '費用管理',
          active: true,
        },
      ],
      dialogButtons: [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
      ],
      currentPage: 1,
      perPage: 10,
      optionsPaging: [10, 50, 100],
      total: 0,
      VIEW_COST: VIEW_COST,
      selectedViewCost: null,
      selectedYear: null,
      formData: [],
      appItems: [],
      departmentItems: [],
      staffItems: [],
      viewCostOptions: listOfCostData.getViewOptions(),
      yearOptions: [],
      appFields: listOfCostData.getAppFields(),
      departmentFields: listOfCostData.getDepartmentFields(),
      staffFields: listOfCostData.getStaffFields(),
      dialogFields: [],
      customTitle: false,
      customTitleDetail: {
        title: '',
        year: '',
        serviceName: '',
      },
      dataDepartment: [],
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['user_status', 'apps_category']),
  },
  watch: {
    searchInput(newValue) {
      this.handleValueChange(newValue)
    },
    perPage() {
      this.handleValueChange()
    },
    currentPage() {
      this.handleValueChange()
    },
  },
  async created() {
    this.getYearOptions()
    this.selectedViewCost = VIEW_COST.APP
    await this.reloadTable()
    this.selectedYear = new Date().getFullYear()
    const { items } = await departmentManagementService.getListDepartment(100000)
    this.dataDepartment = items
  },
  mounted() {
    this.selectedViewCost = parseInt(this.$route.query.selectedViewCost) || VIEW_COST.APP
    this.selectedYear = parseInt(this.$route.query.selectedYear) || new Date().getFullYear()
    this.searchInput = this.$route.query.searchInput || ''
  },
  methods: {
    async reloadTable() {
      if (this.selectedViewCost === VIEW_COST.APP) {
        const { items, total } = await listOfCostData.getAppItems()
        this.appItems = items
        this.total = total
        this.searchInput = ''
      }
      if (this.selectedViewCost === VIEW_COST.DEPARTMENT) {
        const { items, total } = await listOfCostData.getDepartmentItems()
        this.departmentItems = items
        this.total = total
        this.searchInput = ''
      }
      if (this.selectedViewCost === VIEW_COST.STAFF) {
        const { items, total } = await listOfCostData.getStaffItems()
        this.staffItems = items
        this.total = total
        this.searchInput = ''
      }
    },
    async handleValueChange(keyword) {
      if (this.searchInput) {
        this.$router.push({
          query: {
            selectedViewCost: this.selectedViewCost,
            selectedYear: this.selectedYear,
            searchInput: this.searchInput,
          },
        })
      }
      if (this.selectedViewCost === VIEW_COST.APP) {
        const { items, total } = await listOfCostData.getAppItems(
          this.selectedYear,
          this.perPage,
          this.currentPage,
          keyword
        )
        this.appItems = items
        this.total = total
      }
      if (this.selectedViewCost === VIEW_COST.DEPARTMENT) {
        const { items, total } = await listOfCostData.getDepartmentItems(
          this.selectedYear,
          this.perPage,
          this.currentPage,
          keyword
        )
        this.departmentItems = items
        this.total = total
      }
      if (this.selectedViewCost === VIEW_COST.STAFF) {
        const { items, total } = await listOfCostData.getStaffItems(
          this.selectedYear,
          this.perPage,
          this.currentPage,
          keyword
        )
        this.staffItems = items
        this.total = total
      }
    },
    dataChange() {
      this.handleValueChange()
      this.$router.push({
        query: {
          selectedViewCost: this.selectedViewCost,
          selectedYear: this.selectedYear,
          searchInput: this.searchInput,
        },
      })
      this.searchInput = ''
    },
    async showModal(evn) {
      this.customTitle = false
      if (this.selectedViewCost === VIEW_COST.APP) {
        this.customTitle = true
        this.dialogFields = listOfCostData.getDialogFields()
        this.customTitleDetail.serviceName = evn.data.item.name
        this.customTitleDetail.year = this.selectedYear
        this.customTitleDetail.title = '利用料金状況'
        const items = await listOfCostData.getAppDialogItems(evn.data.item.id, this.selectedYear)
        this.formData = [...items.items].sort((a, b) => a.month - b.month)
      } else if (this.selectedViewCost === VIEW_COST.DEPARTMENT) {
        this.customTitle = true
        this.dialogFields = listOfCostData.getDialogFields()
        this.customTitleDetail.serviceName = evn.data.item.name
        this.customTitleDetail.year = this.selectedYear
        this.customTitleDetail.title = '利用料金状況'
        const items = await listOfCostData.getDepartmentDialogItems(
          evn.data.item.id,
          this.selectedYear
        )
        this.formData = [...items.items].sort((a, b) => a.month - b.month)
      } else {
        this.customTitle = true
        this.dialogFields = listOfCostData.getDialogFieldsStaff()
        this.customTitleDetail.serviceName = evn.data.item.name
        this.customTitleDetail.year = this.selectedYear
        this.customTitleDetail.title = '利用料金状況'
        const items = await listOfCostData.getStaffDialogItems(evn.data.item.id, this.selectedYear)
        this.formData = [...items.items].sort((a, b) => a.month - b.month)
      }
      this.$bvModal.show(this.id)
    },
    statusClass(status) {
      if (status === USER_STATUS.ACTIVE) {
        return 'cell__status cell__status-active'
      }
      return 'cell__status cell__status-inactive'
    },
    getStatusLabel(status) {
      return get(this.user_status.filter((data) => data.id === status)[0], 'name', '')
    },
    getCategoriesLabel(categoryId) {
      return get(this.apps_category.filter((data) => data.id === categoryId)[0], 'name', '')
    },
    getDepartmentLable(departmentId) {
      return get(this.dataDepartment.filter((data) => data.id === departmentId)[0], 'name', '')
    },
    getYearOptions() {
      this.yearOptions = getYearsOptions().map((item) => {
        return {
          value: item,
          text: item + '年',
        }
      })
    },
  },
}
</script>
