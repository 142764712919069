<template>
  <form>
    <div class="table-responsive Cost_Dialog_Table">
      <b-table
        ref="cost-detail"
        hover
        :fields="dialogFields"
        :items="formData"
        sort-by="month"
        size="sm"
        thead-class="Table__header Cost_Dialog_Table__header"
        :tbody-tr-class="rowClass"
      >
        <template #head()="data">
          <div v-if="!!data.label" class="header">
            <div class="header--value">
              <span>{{ data.label }}</span>
            </div>
            <div v-if="data.field.sortable" class="header--sort">
              <SortIcon />
            </div>
          </div>
          <div v-else class="action">
            {{ '' }}
          </div>
        </template>
        <template #cell(month)="data">
          <div class="">{{ Number(data.value) }}</div>
        </template>
        <template #cell(monthly_price)="data">
          <div class="">{{ data.value }}円</div>
        </template>
      </b-table>
    </div>
  </form>
</template>

<script>
import SortIcon from '@/components/sort-icon'

export default {
  name: 'ListOfCostModalForm',
  components: {
    SortIcon,
  },
  props: {
    formData: {
      type: Array,
      default: null,
    },
    dialogFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentDate: new Date(),
    }
  },
  computed: {
    currentMonth() {
      return this.currentDate.getMonth() + 1
    },
    currentYear() {
      return this.currentDate.getFullYear()
    },
  },
  methods: {
    rowClass(rowData) {
      if (
        Number(rowData.month) === this.currentMonth &&
        Number(rowData.year) === this.currentYear
      ) {
        return 'Table__row Cost_Dialog_Table__row Cost_Dialog_Table__row--active'
      }
      if (Number(rowData.month) > this.currentMonth && Number(rowData.year) === this.currentYear) {
        return 'Table__row Cost_Dialog_Table__row Cost_Dialog_Table__row--inactive'
      }
      return 'Table__row Cost_Dialog_Table__row'
    },
  },
}
</script>
