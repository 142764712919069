import axios from '@/configs/http/internal-api-axios'

const appUrl = '/api/v1/cost/apps'
const departmentUrl = '/api/v1/cost/department'
const staffUrl = '/api/v1/cost/users'

async function getAppItems(
  year = new Date().getFullYear(),
  perPage = 10,
  currentPage = 1,
  keyword = ''
) {
  const url = `${appUrl}?year=${year}&perPage=${perPage}&currentPage=${currentPage}&keyword=${keyword}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

async function getDepartmentItems(
  year = new Date().getFullYear(),
  perPage = 10,
  currentPage = 1,
  keyword = ''
) {
  const url = `${departmentUrl}?year=${year}&perPage=${perPage}&currentPage=${currentPage}&keyword=${keyword}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

async function getStaffItems(
  year = new Date().getFullYear(),
  perPage = 10,
  currentPage = 1,
  keyword = ''
) {
  const url = `${staffUrl}?year=${year}&perPage=${perPage}&currentPage=${currentPage}&keyword=${keyword}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

async function getAppDialogItems(id, year) {
  const url = `${appUrl}/${id}/months?year=${year}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data,
  }
}

async function getDepartmentDialogItems(id, year) {
  const url = `${departmentUrl}/${id}/months?year=${year}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data,
  }
}

async function getStaffDialogItems(id, year) {
  const url = `${staffUrl}/${id}/months?year=${year}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data,
  }
}

function getAppFields() {
  return [
    { key: 'logo', label: 'ロゴ' },
    { key: 'name', label: 'サービス名', sortable: true },
    { key: 'category_id', label: 'カテゴリー', sortable: true },
    { key: 'staff_used', label: '利用中アカウント数', sortable: true },
    { key: 'company_members', label: '従業員数', sortable: true },
    { key: 'app_cost', label: '費用', sortable: true },
    { key: 'app_percent', label: '費用割合(費用/ SaaS年間累計額)', sortable: true },
  ]
}

function getDepartmentFields() {
  return [
    { key: 'name', label: '部署名' },
    { key: 'users', label: '従業員数', sortable: true },
    { key: 'sass', label: '利用中のサービス数', sortable: true },
    { key: 'month_average_price', label: '平均費用', sortable: true },
    { key: 'month_ago_price', label: '前月費用', sortable: true },
  ]
}

function getStaffFields() {
  return [
    { key: 'name', label: '従業員名' },
    { key: 'department_id', label: '部署', sortable: true },
    { key: 'use_app', label: '利用中のサービス数', sortable: true },
    { key: 'cost_average_year', label: '平均費用', sortable: true },
    { key: 'cost_month_ago', label: '前月費用', sortable: true },
    { key: 'status', label: 'ステータス', sortable: true },
  ]
}

function getDialogFields() {
  return [
    { key: 'month', label: '月', sortable: true },
    { key: 'users', label: '紐付け済みのアカウント数', sortable: true },
    { key: 'monthly_price', label: '費用', sortable: true },
  ]
}

function getDialogFieldsStaff() {
  return [
    { key: 'month', label: '月', sortable: true },
    { key: 'apps', label: '利用中の     サービス数', sortable: true },
    { key: 'monthly_price', label: '費用', sortable: true },
  ]
}

function getViewOptions() {
  return [
    { value: 1, text: 'SaaS' },
    { value: 2, text: '部署' },
    { value: 3, text: '従業員' },
  ]
}

export const listOfCostData = {
  getStaffFields,
  getDepartmentFields,
  getAppFields,
  getStaffItems,
  getDepartmentItems,
  getAppItems,
  getViewOptions,
  getDialogFields,
  getDialogFieldsStaff,
  getAppDialogItems,
  getDepartmentDialogItems,
  getStaffDialogItems,
}
